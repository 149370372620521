.header-user{
    background:$dark-color;
    padding:40px 0;
    color:$light-color;
    font-family:$secondary-font;
    font-weight: $medium-sf;
    h1{
        font-size:3.5rem;
        padding-bottom:36px;
        font-family:$primary-font;
    }
    small{
        font-size:0.875rem;
    }
    h5{
        font-size:1.5rem;
    }
    .fa-user-circle{
        font-size:2.5rem;
        color:$light-primary;
    }
    .fa-angle-down{
        font-size:1.5rem;
        color:$light-primary;
    }
    a{
      color:$light-color;
    }
}
