.onpg{
  .header-box{
    background-color: $third-color;
    color: $light-color;
  }
  .events{
    background: $lightest-primary;
    margin-right: -10px;
    margin-left: -10px;
    .container-events{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
