@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900|Philosopher:400,700');

$primary-font: 'Philosopher', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

$regular-pf:400;
$bold-pf:700;

$light-sf:300;
$regular-sf:400;
$medium-sf:500;
$semibold-sf:600;
$bold-sf:700;
$extrabold-sf:800;
$black-sf:900;

$primary-color: #000000;
  $lighten-primary: lighten($primary-color, 40.00); //##666666
  $light-primary: lighten($primary-color, 60.00); //#999999
  $dark-color: lighten(saturate(adjust-hue($primary-color, 197), 6.19), 22.16); //#353a3c
  $lightest-primary: lighten($primary-color, 92.55); //#ececec
$light-color: #fff;

$first-color: #004b72;
  $lighten-first-color: lighten($first-color, 27.65); //#00a9ff
  $lightest-first-color: lighten(desaturate(adjust-hue($first-color, -33), 32.43), 70.39); //#e0f2f9
$second-color: #007253;
$third-color: #001372;
$fourth-color: #720033;
$fifth-color: #f6a14d;
