body{
  font-family:$secondary-font;
  font-weight:$regular-pf;
  font-size: 16px;
}
a{
  color: $primary-color;
  text-decoration: none;
  &:hover{
    color: $primary-color;
    text-decoration: none;
  }
}
.border-bottom{
  border-bottom: 1px solid $lightest-primary;
}
// BUTTONS
.btn{
  border-radius: 3rem;
  border-top-right-radius: 0px;
  text-transform: uppercase;
  @include media-breakpoint-down(lg) {
    font-size: .8rem;
  }
  &.btn-info{
    background-color: $fifth-color;
    border-color: $fifth-color;
    color: $light-color !important;
  }
  &.btn-utility{
    background: $lightest-first-color;
    border-radius: .4rem;
    border-top-right-radius: 0px;
    font-size: 1.5em;
    text-transform: none;
    font-weight: $semibold-sf;
    @include media-breakpoint-down(lg) {
      font-size: .8rem;
    }
  }
}
.tit-box{
  font-size: 1.875em;
  font-family: $primary-font;
  @extend .pb-3;
}
.header-box{
  font-family:$primary-font;
  @extend .mt-3;
  @extend .py-3;
  @extend .text-center;
  border-top-left-radius: 6px;
  background: url(img/ico-lock.png) center right no-repeat;
  h2{
    font-size: 2.25em;
    font-weight: 700;
    @extend .py-0;
    @extend .mb-0;
  }
  h3{
    font-size: 1.125em;
    font-weight: 400;
    @extend .py-0;
    @extend .mb-0;
  }
}
.content-section{
  border: 1px solid $lightest-primary;
  border-top: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  @extend .p-3;
  @extend .mb-3;
}
.ico-standard{
  fill:$first-color !important;
}
.evento{
  margin-bottom: 1em;
  .figure{
    align-self: flex-start!important;
    img{
      max-width: 100%;
    }
    .label-cat{
      position: absolute;
      bottom: 0px;
      padding: .25em;
      text-align: center;
      left: 15px;
      right: 15px;
      background: #ccc;
      //color: $primary-light;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;
      //font-family: $secondary-font;
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: .7em;
      }
    }
  }
  header{
    .data{
      font-weight: 700;
      color: $lighten-first-color;
      @extend .mb-3;
    }
    h5{
      font-size: 1.5em;
      font-weight: 700;
    }
    .subtitle{
      color: $lighten-primary;
      font-family: $primary-font;
      font-size: 1.125em;
      @extend .mb-3;
    }
    .citta{
      color: $light-primary;
      font-size: .875em;
    }
    span.under-title {
      display: block;
      height: 2px;
      width: 25%;
      background: $lighten-first-color;
    }
  }
}
